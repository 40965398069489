.trigger {
	display: inline-flex;
	cursor: pointer;
}

.content {
	position: absolute;
	transform: translate(-50%, -100%);
	background-color: var(--product-card-background);
	color: #fff;
	border-radius: 4px;
	white-space: nowrap;
}
